import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    countNum: 199,
    countTitle: "Happy Clients.",
    description:
      "We’ve earned the trust of businesses worldwide by delivering impactful digital solutions and exceptional service.",
  },
  {
    countNum: 575,
    countTitle: "Dedicated Employees",
    description:
      "Our talented team works passionately to bring your vision to life, offering personalized solutions for every project.",
  },
  {
    countNum: 69,
    countTitle: "Innovative Programs",
    description:
      " Through our advanced tools and programs, we empower businesses to innovate, grow, and succeed in the digital landscape. ",
  },
];
const CounterUpTwo = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <TrackVisibility once>
              {({ isVisible }) =>
                isVisible && (
                  <div className="count-number">
                    {isVisible ? <CountUp end={data.countNum} /> : 0}
                  </div>
                )
              }
            </TrackVisibility>
            <h5 className="title">{data.countTitle}</h5>
            <p className="description">{data.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CounterUpTwo;
