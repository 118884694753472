import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import AdvanceTabOne from "./AdvanceTabOne";
import AdvanceTabTwo from "./AdvanceTabTwo";
// import AdvanceTabThree from "./AdvanceTabThree";
import Separator from "../separator/Separator";

const AdvanceTab = () => {
  return (
    <>
      <SEO title="Full-spectrum digital solutions, future-ready partnership." />
      <Layout>
        <BreadcrumbOne
          title="Full-spectrum digital solutions, future-ready partnership."
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Advance Tab"
        />

        <div className="main-content">
          {/* Start Elements Area  */}
          <div className="rwt-advance-tab-area rn-section-gap">
            <div className="container">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle=""
                    title="Why Choose Us?"
                    description=""
                  />
                </div>
              </div>
              <AdvanceTabOne />
            </div>
          </div>
          {/* End Elements Area  */}

          <Separator />

          <Separator />

          {/* Start Elements Area  */}
        </div>
      </Layout>
    </>
  );
};
export default AdvanceTab;
