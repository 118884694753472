import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import "bootstrap/dist/css/bootstrap.min.css";

const callToActionData = {
  title: "Ready to Get Started?",
  subtitle: "Book a free consultation to discuss your project",
  btnText: "Get A Quote",
};

const questions = [
  {
    id: "name",
    question: "What is your name?",
    type: "text",
    placeholder: "Enter your full name",
    required: true,
  },
  {
    id: "service",
    question: "What service do you require?",
    type: "radio",
    options: ["Web Development", "SEO Optimization", "Social Media Marketing"],
    required: true,
  },
  {
    id: "company",
    question: "What is your company name?",
    type: "text",
    placeholder: "Enter your company name",
    required: true,
  },
  {
    id: "address",
    question: "What is your address?",
    type: "text",
    placeholder: "Enter your address",
    required: true,
  },
  {
    id: "postal",
    question: "What is your post code?",
    type: "text",
    placeholder: "Enter your postal code",
    required: true,
  },
  {
    id: "number",
    question: "What is your phone number?",
    type: "text",
    placeholder: "Enter your phone number",
    required: true,
  },
  {
    id: "email",
    question: "Email Address?",
    type: "text",
    placeholder: "Enter your email",
    required: true,
  },
  {
    id: "needs",
    question: "What are your website needs?",
    type: "radio",
    options: ["Create a new website", "Update existing website", "Other"],
    required: true,
  },
  {
    id: "service",
    question: "What are your website needs?",
    type: "radio",
    options: [
      "To advertise my business/services",
      "To sell products/services e.g. e-commerce",
      "Both advertising and selling",
    ],
    required: true,
  },
  {
    id: "service",
    question: "What type of business is this for?",
    type: "radio",
    options: [
      "Sole trader/self-employed",
      "Limited company",
      "Partnership",
      "Charity/Non-profit",
    ],
    required: true,
  },
  {
    id: "service",
    question: "What industry do you operate in?",
    type: "radio",
    options: [
      "Health and wellbeing",
      "Retail",
      "Professional services",
      "Technology",
      "Other Company",
    ],
    required: true,
  },
  {
    id: "service",
    question: "When would you like the website to go live/be updated?",
    type: "radio",
    options: [
      "As soon as possible",
      "Within a few months",
      "Within 6 months",
      "No specific timeline",
    ],
    required: true,
  },
  {
    id: "budget",
    question: "What is your budget?",
    type: "radio",
    options: [
      "Under £250",
      "£250 - £999",
      "£1000 - £2499",
      "£2500 - £4999",
      "£500+",
    ],
    required: true,
  },
  {
    id: "service",
    question: "How likely are you to make a hiring decision?",
    type: "radio",
    options: [
      "Ready to start",
      "I'm planning and researching",
      "Just exploring options",
    ],
    required: true,
  },
  {
    id: "message",
    question: "Do you have any additional details?",
    type: "textarea",
    placeholder: "Share any additional details about your project",
    required: true,
  },
  {
    id: "terms",
    question: "Terms and Conditions",
    type: "radio",
    options: ["I accept the terms and conditions"],
    required: true,
  },
];

const CalltoActionEight = () => {
  const [showForm, setShowForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState(false);

  const handleInputChange = (id, value) => {
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentQuestion = questions[currentStep];
    if (currentQuestion.required && !formData[currentQuestion.id]) {
      alert(`Please complete the "${currentQuestion.question}" field.`);
      return;
    }

    if (currentStep < questions.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      // Prepare email body
      const mailBody = Object.entries(formData)
        .map(([key, value]) => `${key}: ${encodeURIComponent(value)}`)
        .join("\n");

      // Send email using mailto link
      const mailToLink = `mailto:contact@whatson.agency?subject=New Quote Request&body=${encodeURIComponent(
        mailBody
      )}`;
      window.location.href = mailToLink;

      setSubmissionMessage(true);
      setFormData({});
      setShowForm(false);
      setCurrentStep(0);
    }
  };

  return (
    <div className="container mt-5 border-danger">
      <div className="text-center mb-4 text-black border-danger">
        <h2>{callToActionData.title}</h2>
        <p>{callToActionData.subtitle}</p>
        {!showForm && !submissionMessage && (
          <button
            className="btn btn-primary btn-default btn-icon"
            onClick={() => setShowForm(true)}
          >
            {callToActionData.btnText} <FiArrowRight />
          </button>
        )}
      </div>

      {submissionMessage && (
        <div className="card shadow-lg border-0 mx-auto col-lg-3 mt-5">
          <div className="border-danger card-body text-center bg-color-blackest">
            <h4 className="text-success theme-gradient">
              Thank you for your submission!
            </h4>
            <p className="text-white  bold">We will contact you shortly.</p>
            <button
              className="btn btn-default btn-icon btn-sm mt-3"
              onClick={() => setSubmissionMessage(false)}
            >
              Back to Form
            </button>
          </div>
        </div>
      )}

      {showForm && (
        <div className="card shadow-lg border-danger border-1 col-lg-4 mx-auto p-3 bg-color-blackest text-white radius mt-4">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <h3 className="mb-4 text-center theme-gradient">
                {questions[currentStep].question}
              </h3>
              {questions[currentStep].type === "text" && (
                <input
                  type="text"
                  className="form-control mb-3 bg-transparent text-white border-0 p-2 fw-bold"
                  placeholder={questions[currentStep].placeholder}
                  value={formData[questions[currentStep].id] || ""}
                  onChange={(e) =>
                    handleInputChange(questions[currentStep].id, e.target.value)
                  }
                  required={questions[currentStep].required}
                />
              )}
              {questions[currentStep].type === "textarea" && (
                <textarea
                  className="form-control mb-3 bg-transparent text-white border-0 p-2 fw-bold fs-2"
                  placeholder={questions[currentStep].placeholder}
                  value={formData[questions[currentStep].id] || ""}
                  onChange={(e) =>
                    handleInputChange(questions[currentStep].id, e.target.value)
                  }
                  rows={10}
                  required={questions[currentStep].required}
                />
              )}
              {questions[currentStep].type === "radio" && (
                <div className="d-flex flex-column gap-2">
                  {questions[currentStep].options.map((option) => (
                    <div
                      key={option}
                      className="form-check border rounded p-3 fs-1"
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        id={option}
                        name={questions[currentStep].id}
                        value={option}
                        checked={formData[questions[currentStep].id] === option}
                        onChange={(e) =>
                          handleInputChange(
                            questions[currentStep].id,
                            e.target.value
                          )
                        }
                      />
                      <label
                        className="form-check-label fw-bold"
                        htmlFor={option}
                      >
                        {option}
                      </label>
                      {option === "Other" &&
                        formData[questions[currentStep].id] === "Other" && (
                          <textarea
                            className="form-control mt-3 bg-transparent text-white border-0 p-2 fw-bold fs-2"
                            placeholder="Please share your thoughts"
                            value={formData.other || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                other: e.target.value,
                              })
                            }
                            rows={4}
                            required
                          />
                        )}
                    </div>
                  ))}
                </div>
              )}

              <div className="d-flex justify-content-between mt-4 border-danger">
                {currentStep > 0 && (
                  <button
                    type="button"
                    className="btn btn-default btn-icon "
                    onClick={() => setCurrentStep((prev) => prev - 1)}
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-primary btn-default btn-icon "
                >
                  {currentStep < questions.length - 1 ? "Next" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalltoActionEight;
