import React from "react";
import { FiCheck, FiStar } from "react-icons/fi";
import { FaLaptopCode, FaSearch, FaShareAlt } from "react-icons/fa"; // Importing relevant icons for the services

const AdvancePricingOne = () => {
  return (
    <div className="advance-pricing">
      <div className="inner">
        <div className="row row--0">
          <div className="col-lg-6">
            <div className="pricing-left">
              <h3 className="main-title text-center">Special Offer</h3>
              <p className="description text-center">
                Specialized solutions with 30 days support included
              </p>
              <div className="price-wrapper">
                <span className="price-amount">
                  20% OFF <sup></sup>
                </span>
                <div className="coupon-box text-center ">
                  <input
                    type="text"
                    value="WOASAVE20"
                    id="couponCode"
                    readOnly
                  />
                  <button
                    className="btn-default"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        document.getElementById("couponCode").value
                      );
                      alert("Coupon code copied!");
                    }}
                  >
                    Copy Code
                  </button>
                </div>
              </div>
              <div className="rating">
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
              </div>
              <span className="subtitle">
                rated 4.5/5 Stars in 1000+ reviews.
              </span>
              <h5 className="popular text-center mt--40">
                Most Popular Services
              </h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pricing-right">
              <div className="pricing-offer">
                <div className="single-list">
                  <h4 className="price-title">
                    <FaLaptopCode className="me-2" /> Web Development
                  </h4>
                  <p>
                    Custom websites built with modern technologies, optimised
                    for performance and user experience.
                  </p>
                </div>
                <div className="single-list">
                  <h4 className="price-title">
                    <FaSearch className="me-2" /> SEO Optimisation
                  </h4>
                  <p>
                    Improve your search rankings and drive organic traffic with
                    our proven SEO strategies.
                  </p>
                </div>
                <div className="single-list">
                  <h4 className="price-title">
                    <FaShareAlt className="me-2" /> Social Media Marketing
                  </h4>
                  <p>
                    Engage your audience and build brand awareness across all
                    major social platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancePricingOne;
