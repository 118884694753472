import React from "react";
import { FiCheck } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";

// Reusable PricingCard Component with dynamic text content
const PricingCard = ({
  title,
  price,
  priceType,
  features,
  buttonText,
  badgeText,
  buttonLink,
  badgeColor,
  description,
}) => {
  return (
    <div className="container">
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <div
          className="card border-danger mx-2 mb-4"
          style={{
            maxWidth: "450px",
            borderRadius: "20px", // Border radius for the card
            overflow: "hidden", // Ensures content stays within the radius
          }}
        >
          <div className="card-header bg-color-blackest border-0 position-relative">
            <h4 className="mb-0 mt--10">
              <strong>{title}</strong>
            </h4>
            {badgeText && (
              <span
                className={`badge text-white position-absolute top-0 end-0 mt-2 me-2 bg-${badgeColor} d-inline-block`}
                style={{
                  fontSize: "14px", // Default font size
                  whiteSpace: "nowrap", // Prevent text wrapping
                }}
              >
                {badgeText}
              </span>
            )}
          </div>
          <div
            className="card-body bg-color-blackest"
            style={{ padding: "20px" }}
          >
            {/* Description text */}
            <p className="card-text">{description}</p>

            <h3 className="bg-color-blackest">
              <strong className="theme-gradient">{price}</strong>{" "}
              <span
                className="text-white text-muted"
                style={{
                  fontSize: "14px", // Smaller font size for One-time
                  verticalAlign: "middle", // Aligns it properly with the text
                }}
              >
                {priceType}
              </span>
            </h3>

            <ul className="list-unstyled mt-3 mb-4">
              {features.map((feature, index) => (
                <li className="d-flex align-items-center mb-2" key={index}>
                  <FiCheck className="text-danger me-2" /> {feature}
                </li>
              ))}
            </ul>
            <a
              href={buttonLink || "#"}
              className="btn-default w-100 text-white text-center fw-bold"
              style={{
                borderRadius: "20px", // Added border radius to the button
                fontSize: "16px", // Increase the font size of the button text
              }}
              onClick={(e) => {
                if (!buttonLink) {
                  e.preventDefault(); // Prevent default behavior if no link is provided
                  alert("Link is not available"); // Inform the user or handle appropriately
                }
              }}
            >
              {buttonText} →
            </a>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

// Component to render multiple Pricing Cards with dynamic content
const OfferPricing = () => {
  const services = [
    {
      title: "Web Development",
      price: "from ৳ 30K",
      priceType: "One-time",
      features: [
        "Responsive Website Design",
        "Up to 5 Pages",
        "Contact Form Integration",
        "Mobile Optimisation",
        "30 Days Technical Support",
        "Basic SEO Setup",
      ],
      buttonText: "Start Web Project",
      buttonLink: "/web-design-and-development",
      badgeText: "Most Popular",
      badgeColor: "danger",
      description: "Custom website design and development solutions",
    },
    {
      title: "SEO Optimization",
      price: "from ৳ 20K",
      priceType: "One-time",
      features: [
        "Keyword Research & Strategy",
        "On-Page SEO Optimization",
        "Technical SEO Audit",
        "Performance Reports",
        "30 Days Support & Monitoring",
        "Content Recommendations",
      ],
      buttonText: "Optimize My Site",
      buttonLink: "/seo",
      badgeText: "",
      badgeColor: "success",
      description: "Boost your search engine rankings and visibility",
    },
    {
      title: "Social Media Marketing",
      price: "from ৳ 10K",
      priceType: "One-time",
      features: [
        "Social Media Strategy",
        "Content Creation",
        "2 Posts per Week",
        "Engagement Monitoring",
        "30 Days Management",
        "Analytics Reports",
      ],
      buttonText: "Start Marketing",
      buttonLink: "/facebook-promotion-and-boosting", // No link provided
      badgeText: "",
      badgeColor: "primary",
      description: "Engage your audience across social platforms",
    },
    // Add more services as needed
  ];

  return (
    <div className="container">
      <div className="row">
        {services.map((service, index) => (
          <div className="col-md-4" key={index}>
            <PricingCard {...service} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OfferPricing;
