import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AdvanceTabOne = () => {
  return (
    <div>
      <Tabs>
        <div className="row row--30">
          <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
            <div className="advance-tab-button advance-tab-button-1">
              <TabList className="tab-button-list">
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Trusted by Businesses Worldwide</h4>
                    <p className="description">
                      We’ve built long-term partnerships with satisfied clients
                      across industries, driving impactful results through
                      strategic planning and execution.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Expert Team at Your Service</h4>
                    <p className="description">
                      Our diverse team of skilled professionals is dedicated to
                      turning your ideas into reality with creativity and
                      precision.
                    </p>
                  </div>
                </Tab>
                <Tab>
                  <div className="tab-button">
                    <h4 className="title">Empowering Tools and Resources</h4>
                    <p className="description">
                      We leverage cutting-edge technology and effective programs
                      to provide solutions that adapt to evolving business
                      landscapes.
                    </p>
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="advance-tab-content advance-tab-content-1">
              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src="./image/tab/tab-1.jpg" alt="Corporate Images" />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./image/tab/tab-22.jpg"
                        alt="Corporate Images"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="tab-content">
                  <div className="inner">
                    <div className="thumbnail">
                      <img
                        src="./image/tab/tab-33.jpg"
                        alt="Corporate Images"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};
export default AdvanceTabOne;
